:root {
	--header: 'Noto Sans JP', sans-serif;
	--text: 'Roboto', sans-serif;
}

* {
	background-color: #a5a58d;
}

/* --------------Nav Bar Styling------------------ */

nav {
	display: flex;
	justify-content: space-around;
	align-self: center;
	position: -webkit-sticky;
	position: sticky;
	height: 60px;
	top: 0;
	z-index: 1;
	background-color: #6b705c;
	border-radius: 5px;
}

nav ul {
	display: flex;
	flex-direction: row;
	margin-top: 0;
	background-color: #6b705c;
}

nav li {
	margin: 20px;
	list-style: none;
	background-color: #6b705c;
}

.link-nav {
	color: white;
	background: transparent;
	text-decoration: none;
	border: #c6d2ed;
	font-size: 18px;
	font-family: var(--text);
}
/* Title Styling */
.title {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	font-size: 40px;
	font-family: var(--header);
	margin: 5%;
	height: 500px;
}

.title h1 {
	overflow: hidden;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: .15em;
	animation: typing 3.5s steps(40, end);
}
/* ----------------------About Me Styling------------------------------ */

.abt-card {
	display: flex;
	flex-direction: column;
	margin: 10%;
	line-height: 50px;
	font-family: var(--text);
	align-items: center;
	align-content: center;
	font-size: 20px;
}

.abt-card h1 {
	font-size: 40px;
	font-family: var(--header);
}

.abt-card aside {
	align-content: flex-start;
}

.abt-card button {
	border-radius: 5px;
	font-family: var(--text);
	padding: 1%;
	width: 25%;
	align-items: center;
}

/* -----------------Project Component Styling---------------------- */
#projects h1 {
	text-align: center;
	font-size: 40px;
	font-family: var(--header);
}

.projectWrapper {
	display: flex;
	flex-flow: column;
	margin-left: 10%;
	margin-right: 10%;
}

.container {
	width: 100%;
	height: auto;
	border: 2px solid white;
	border-radius: 5px;
	margin: 2%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.container:hover {
	box-shadow: 0 4px 8px 0 #ddbea9, 0 6px 20px 0 #ddbea9;
}
.text {
	color: black;
	font-family: var(--text);
	font-size: 18px;
	display: flex;
	padding: 1%;
}

.project-info {
	width: 85%;
}

.project-btns {
	width: 15%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.project-btns button {
	border: 1px solid #ddbea9;
	background-color: #ddbea9;
	border-radius: 5px;
	height: 50px;
	margin: 5%;
}

.project-btns button a {
	background-color: transparent;
}

.project-btns button:hover,
.project-btns button a:hover {
	color: white;
	background-color: #ddbea9;
}

a {
	text-decoration: none;
	color: black;
}

.text ul {
	list-style: none;
	display: flex;
	flex-direction: row;
}

.text ul li {
	margin: 2%;
	border-radius: 5px;
	padding: 1%;
	border: 2px solid #ddbea9;
}

/* Contact Me */

#contact {
	height: 500px;
	display: flex;
	flex-direction: column;
}

#contact h1 {
	font-family: var(--header);
	text-align: center;
	font-size: 40px;
}

.contact-list {
	display: flex;
	justify-content: center;
	list-style: none;
}

.contact-list li {
	margin: 30px;
}

.avatar {
	align-self: center;
}
/* The typing effect */
@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

/* -----------------------------Media Screens----------------------------------- */
@media only screen and (max-width: 1000px) {
	.title h1,
	.title p {
		font-size: 30px;
	}
	.text {
		flex-direction: column;
	}
	.project-info {
		width: 100%;
	}
	.project-info ul {
		display: none;
	}
	.project-btns {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
}
@media only screen and (max-width: 450px) {
	.title h1 {
		animation: none;
		letter-spacing: normal;
		font-size: 20px;
		width: 70%;
	}
	.title p {
		font-size: 20px;
	}
	.contact-list {
		flex-direction: column;
		align-items: center;
	}
	.contact-list li {
		margin: 5%;
	}
}
